<template>
	<v-app>
		<div
			class="tw-h-screen tw-overflow-x-hidden tw-overflow-y-auto tw-flex tw-flex-col"
		>
			<div class="tw-grid tw-grid-cols-1 lg:tw-grid-cols-2 tw-flex-1">
				<div
					class="tw-place-content-center tw-px-4 md:tw-px-0 tw-hidden lg:tw-grid"
				>
					<div class="tw-text-center tw-max-w-md">
						<img
							class="tw-h-52 tw-object-contain tw-mx-auto"
							src="@/assets/images/logo.png"
							alt
							srcset
						/>
						<h3
							class="tw-font-semibold tw-text-2xl md:tw-text-3xl 3xl:tw-text-4xl tw-mt-6 md:tw-mt-10 3xl:tw-mt-14"
						>
							{{ $t('login.welcomeToMachla') }}
						</h3>
						<p class="3xl:tw-text-xl tw-mt-3 3xl:tw-mt-5 tw-text-70">
							{{ $t('login.buyingAndSellingMakeEasy') }}
						</p>
						<div class="tw-mt-40">
							<BtnSignUp />
						</div>
					</div>
				</div>
				<div
					class="tw-grid tw-place-content-center "
					style="background-color: #722432;"
				>
					<div
						class="tw-w-full sm:tw-w-96 md:tw-w-420 tw-px-5 md:tw-px-0 tw-my-12"
					>
						<div>
							<h2 class="tw-font-semibold tw-text-27px tw-text-FF">
								{{ $t('login.login') }}
							</h2>
							<p
								class="tw-text-17px tw-text-FF tw-mt-2 tw-opacity-70 md:tw-mt-12 md:tw-mb-14"
							>
								{{ $t('login.loginToStart') }}
							</p>
						</div>
						<!-- <div class="tw-flex tw-justify-between tw-mt-7 tw-mb-9 ">
							<v-btn depressed class="lg:tw-w-125px tw-w-20 tw-h-12 tw-rounded-lg social-button">
								<v-icon color="white">mdi mdi-facebook</v-icon>
							</v-btn>
							<v-btn depressed class="lg:tw-w-125px tw-w-20 tw-h-12 tw-rounded-lg social-button">
								<v-icon color="white">mdi mdi-google</v-icon>
							</v-btn>
							<v-btn depressed class="lg:tw-w-125px tw-w-20 tw-h-12 tw-rounded-lg social-button">
								<v-icon color="white">mdi mdi-apple</v-icon>
							</v-btn>
						</div> -->
						<v-alert
							dense
							outlined
							dismissible
							v-model="error.dialog"
							type="error"
							>{{ error.message }}</v-alert
						>
						<LoginForm :loading="loading" @login="handleLogin" />
					</div>
				</div>
			</div>
		</div>
		<!-- <div class="overflow-visible login__card ">
      <v-card dark :loading="loading" :disabled="loading" class="glass blur-1">
        <div class="px-3 py-12 px-md-8">
          <v-card-title class="title login__title flex-column"
            >Welcome back!</v-card-title
          >
          <v-card-subtitle class="text-center baody-2"
            >Login with your Email and password</v-card-subtitle
          >
          <v-card-text class="mt-5">
            <v-alert
              dense
              outlined
              dismissible
              v-model="error.dialog"
              type="error"
            >
              {{ error.message }}
            </v-alert>
          </v-card-text>
        </div>
      </v-card>
		</div>-->
	</v-app>
</template>

<script>
import { mapActions } from 'vuex'
import { DEFAULT_ROUTE } from '../consts'

import LoginForm from '@/components/forms/LoginForm'
import BtnSignUp from '../components/BtnSignUp.vue'

export default {
	name: 'Login',
	components: {
		LoginForm,
		BtnSignUp,
	},
	// metaInfo: {
	// 	title: 'Login | Machla',
	// },
	data() {
		return {
			loading: false,
			error: {
				dialog: false,
				message: '',
			},
		}
	},
	methods: {
		...mapActions('auth', ['login']),
		async handleLogin(data) {
			this.loading = true
			let [err, res] = await this.login(data)
			if (err) {
				this.loading = false
				return (this.error = {
					dialog: true,
					message: this.$t('errIncorrectEmailPass'),
				})
			}
			this.$toast.success(this.$t('login.success'))
			if (!+res.user.isAcceptTerms) {
				this.$router.push('/user-agreement')
			} else {
				let role = res.user.role
				let { redirect } = { ...this.$route.query }
				this.$router.replace(redirect ? redirect : DEFAULT_ROUTE[role])
			}
		},
	},
}
</script>

<style lang="scss" type="text/scss" scoped>
.login {
	&__card {
		width: 450px;
		max-width: 90%;
		@include center;
	}
}
</style>
