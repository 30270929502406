<template>
	<div class="md:tw-w-420">
		<!-- <div>
			<h2 class="tw-font-semibold tw-text-27px tw-text-FF">Login</h2>
			<p class="tw-text-17px tw-text-FF tw-mt-2 tw-opacity-70">
				Login to start managing your assets and
				monitoring updates.
			</p>
		</div>
		<div class="tw-flex tw-justify-between tw-mt-7 tw-mb-9 md:tw-mt-12 md:tw-mb-14">
			<v-btn depressed class="tw-w-125px tw-h-12 tw-rounded-lg social-button">
				<v-icon color="white">mdi mdi-facebook</v-icon>
			</v-btn>
			<v-btn depressed class="tw-w-125px tw-h-12 tw-rounded-lg social-button">
				<v-icon color="white">mdi mdi-google</v-icon>
			</v-btn>
			<v-btn depressed class="tw-w-125px tw-h-12 tw-rounded-lg social-button">
				<v-icon color="white">mdi mdi-apple</v-icon>
			</v-btn>
		</div> -->
		<v-form
			ref="loginForm"
			v-model="isValid"
			:disabled="loading"
			@submit.prevent="handleLogin"
		>
			<div
				for
				class="tw-mb-4 tw-font-medium tw-flex tw-items-center tw-text-white"
			>
				<!-- <v-icon dark>mdi-email-outline</v-icon> -->
				<base-svg width="19.113" height="14.36" viewBox="0 0 19.113 14.36">
					<path
						id="envelope_2_"
						data-name="envelope (2)"
						d="M1.782,59.882H17.23a1.784,1.784,0,0,1,1.782,1.782V72.359a1.784,1.784,0,0,1-1.782,1.782H1.782A1.784,1.784,0,0,1,0,72.359V61.664A1.784,1.784,0,0,1,1.782,59.882Zm0,1.188a.591.591,0,0,0-.228.046l7.952,6.892,7.952-6.892a.591.591,0,0,0-.228-.046Zm0,11.883H17.23a.6.6,0,0,0,.594-.594V62.372L9.9,69.243a.6.6,0,0,1-.779,0L1.188,62.372v9.987A.6.6,0,0,0,1.782,72.953Z"
						transform="translate(0.05 -59.832)"
						fill="#fff"
						stroke="#fff"
						stroke-width="0.1"
					/>
				</base-svg>

				<div class="tw-mx-4">
					{{ $t('login.EmailAddress') }}
				</div>
			</div>
			<v-text-field
				solo
				:rules="[rules.required($t('email')), rules.email]"
				v-model="user.username"
				:placeholder="$t('login.EmailAddress')"
				class="focus-bg-none"
				hide-details="auto"
			/>
			<div
				for
				class="tw-mb-4 tw-mt-8 tw-font-medium tw-flex tw-items-center tw-text-white"
			>
				<!-- <v-icon dark>mdi-lock</v-icon> -->
				<base-svg
					width="16.138"
					height="22.103"
					viewBox="0 0 16.138 22.103"
				>
					<g
						id="privacy_2_"
						data-name="privacy (2)"
						transform="translate(0)"
					>
						<path
							id="Path_9371"
							data-name="Path 9371"
							d="M172.562,296.177a.648.648,0,0,1-.648-.648v-2.683a.648.648,0,0,1,1.3,0v2.683A.648.648,0,0,1,172.562,296.177Zm0,0"
							transform="translate(-164.492 -279.585)"
							fill="#fff"
						/>
						<path
							id="Path_9372"
							data-name="Path 9372"
							d="M.647,7.1H3.712V3.948A4.172,4.172,0,0,1,8.069,0a4.172,4.172,0,0,1,4.357,3.948V7.1h3.065a.648.648,0,0,1,.648.648v8.281A6.078,6.078,0,0,1,10.067,22.1h-4A6.078,6.078,0,0,1,0,16.032V7.75A.648.648,0,0,1,.647,7.1ZM11.131,3.948A2.883,2.883,0,0,0,8.069,1.295,2.883,2.883,0,0,0,5.007,3.948V7.1h6.123ZM1.3,16.032a4.782,4.782,0,0,0,4.776,4.776h4a4.782,4.782,0,0,0,4.776-4.776V8.4H1.3Zm0,0"
							transform="translate(0 0)"
							fill="#fff"
						/>
					</g>
				</base-svg>

				<div class="tw-mx-4">
					{{ $t('login.password') }}
				</div>
			</div>

			<v-text-field
				solo
				v-model="user.password"
				hide-details="auto"
				:rules="[rules.required($t('login.password')), rules.password]"
				class="focus-bg-none"
				:type="password.show ? 'text' : 'password'"
				:placeholder="$t('login.password')"
				:append-icon="password.show ? 'mdi-eye' : 'mdi-eye-off'"
				@click:append="password.show = !password.show"
			/>

			<div
				class="tw-flex tw-justify-between tw-items-center lg:tw-mt-10 lg:tw-mb-82 tw-mt-6 tw-mb-10"
			>
				<base-checkbox v-model="remember" :label="$t('login.rememberMe')" />
				<router-link
					to="/forgot-password"
					class="pt-2 text-right secondary--text d-block p"
				>
					<span class="tw-text-19 tw-font-semibold tw-text-white">
						{{ $t('login.forgotPassword') }}
					</span>
				</router-link>
			</div>

			<div class="text-center">
				<v-btn
					dark
					height="60"
					block
					type="submit"
					color="#A53E50"
					:loading="loading"
					class="shadow tw-flex tw-justify-between tw-px-4 xl:tw-px-8"
				>
					<v-icon>mdi-exit-to-app</v-icon>
					<span
						class="tw-text-18 tw-font-pop tw-normal-case tw-text-base xl:tw-text-lg tw-font-normal tw-text-center tw-flex-1"
					>
						{{ $t('login.login') }}
					</span>
				</v-btn>
			</div>
		</v-form>
	</div>
</template>

<script>
import { createFormMixin } from '@/mixins/form-mixin'
export default {
	name: 'LoginForm',
	mixins: [
		createFormMixin({
			rules: ['required', 'email', 'password'],
		}),
	],
	props: {
		loading: Boolean,
	},
	data() {
		return {
			isValid: false,
			user: {
				username: '',
				password: '',
			},
			remember: false,
			password: {
				show: false,
				confirmed: false,
			},
		}
	},
	methods: {
		handleLogin() {
			if (!this.$refs.loginForm.validate()) {
				this.$utils.scrollIntoError(this)
				return
			}
			this.$emit('login', {
				...this.user,
				remember: this.remember ? 1 : 0,
			})
		},
	},
}
</script>

<style>
.v-input__append-inner .v-icon {
	color: #a53e50 !important;
}
.social-button {
	background-color: rgba(255, 255, 255, 0.17) !important;
}
</style>
